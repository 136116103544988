import React, { useState, useCallback, useRef, useEffect } from 'react';
import useLang from '../../Hooks/useLang';
import useInView from '../../Hooks/useInView';

const defStyle = {
	objectFit : 'contain',
	maxWidth : '100%',
	maxHeight : '100%'
};

const dataRegex = /^\s*data:([a-z]+\/[a-z0-9\-+]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64),[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;

const _Image = function({src, style, className, alt, ...others}){
	
	const el = useRef(null);
	const [source, setSource] = useState( () => {
		if('loading' in HTMLImageElement.prototype || dataRegex.test(src))
			return src;
		else
			return null;
	});
	
	useEffect(() => {
		if('loading' in HTMLImageElement.prototype || dataRegex.test(src))
			setSource(src);
		else
			setSource(null);
	}, [src]);
	
	const inViewCallback = useCallback(() => {
		setSource(src);
	}, [src]);
	
	
	useInView(el, inViewCallback, source == null);
	
	const text = useLang(alt);
	
	const attrs = {
		...others,
		className : `${className} lazyimage${source == null ? ' ghostLoad' : ''}`,
		title : text,
		style : {...defStyle, ...style},
		loading : "lazy"
	};
	
	if(source != null)
		attrs.src = source;
	
	return (
		<img ref={el} alt={text} {...attrs}/>
	);
};

_Image.defaultProps = {
	alt : "",
	className : "",
	style : {},
	src : null
};

export const Image = React.memo(_Image);