import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk'
import  * as reducers from './reducers';
import config from '../config';
import { reducer as toastrReducer } from 'react-redux-toastr'


//todo it is ugly for third party libs to load
const reducerFunctions = {
	toastr : toastrReducer
};
const persistantStates = {};
/*const initialStates = {
	toastr : {
		toastrs: [],
		confirm: null
	}
};*/

for(const key in reducers){
	reducerFunctions[key] = reducers[key].reducer;
	//initialStates[key] = reducers[key].initialState;
	if(reducers[key].persist)
		persistantStates[key] = true;
}

const saveState = function(state){
	const newState = {};

	//for(const key in state){
		for(const key in persistantStates){
			newState[key] = state[key];
		}/*else{
			newState[key] = initialStates[key];
		}
	}*/
	
	try {
		const serializedState = JSON.stringify(newState);
		localStorage.setItem(`${config.localstorageKey}_state`, serializedState);
	} catch(err){
		console.log(err);
	}
};

const loadState = function(){
	let r = {};
	try {
		const oldState = localStorage.getItem(`${config.localstorageKey}_state`);
		
		if (oldState != null) {
			r = JSON.parse(oldState);
		} 
	} catch (err) {
		console.log(err);
	}
	return r;
};

const store = createStore(
	combineReducers(reducerFunctions),
	loadState(),
	applyMiddleware(
		thunkMiddleware
	)
);

let saveStateTimer = null;

const saveStateTimerFun = function(){	
	saveStateTimer = null;
	saveState(store.getState());
};

store.subscribe(function(){
	if(saveStateTimer != null)
		clearTimeout(saveStateTimer);
	
	saveStateTimer = setTimeout(saveStateTimerFun, 1000);
});

export default store;