import React from 'react';
import './HeroImage.scss';

const _HeroImage = function( {img, children} ){
	return (
		<div className="hero-wrapper">
			{
				img && <div className="hero-image" style={{backgroundImage : `url(${img})`}}></div>
			}
			<div className="hero-gradient" ></div>
			
			<div className="hero-content" >
				{ children }
			</div>
		</div>
	);
};

_HeroImage.defaultProps = {
	img : false
};

export const HeroImage = React.memo(_HeroImage);