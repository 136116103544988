import './MainContent.css';
import { useSelector } from 'react-redux';
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useHistory, Switch, Route, Redirect } from "react-router-dom";
import { LazyLoader } from "../LazyLoader/LazyLoader";
import LoginSkeleton from "../RouteLogin/LoginSkeleton";
import ABMSkeleton from "../RouteABM/ABMSkeleton";
import NoEncontradosSkeleton from "../RouteNoEncontrados/NoEncontradosSkeleton";
import ABMUpdateSkeleton from "../RouteABMUpdate/ABMUpdateSkeleton";
import ABMDeleteSkeleton from "../RouteABMDelete/ABMDeleteSkeleton";
import MyUserSkeleton from "../RouteMyUser/MyUserSkeleton";
import WallSkeleton from "../RouteWall/WallSkeleton";
import ScannerSkeleton from "../Scanner/ScannerSkeleton";
import { ErrorScreen } from "../ErrorScreen/ErrorScreen";
import { Modal } from "../Modal/Modal";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faTimes);

export const MainContent = React.memo(function(){
	const renderArea = useRef(null);

	const history = useHistory();
	const location = useLocation();
	const background = location.state && location.state.background;

	const isLogged = useSelector(state => state.auth.logged);
	const authLevel = useSelector(state => state.auth.user.type);
	const [prevPath, setPrevPath] = useState(location.pathname);

	const goBack = useCallback(e => {
		if(history.length === 0){
			history.push("/");
		}else{
			history.goBack();
		}
	}, [history]);

	useEffect(() => {
		if(background || prevPath === location.pathname) return;

		renderArea.current.scrollTop = 0;
		setPrevPath(location.pathname);
	}, [prevPath, background, location.pathname]);

	const routes = useMemo(() => {

		const r = [<Route key="404" path="/404">
			<ErrorScreen title={"notfound.error.title"}
				description={"notfound.error.description"}
				action={"notfound.error.action"}/>
		</Route>]

		if(!isLogged){
			/*
			r.push(<Route key="restaurar" path="/restaurarcontrasena/:jwt">
				<LazyLoader component={() => import("../RouteRestorePassword/RouteRestorePassword")} fallback={<LoginSkeleton/>} />
			</Route>);

			r.push(<Route key="olvide" path="/olvidemicontrasena">
				<LazyLoader component={() => import("../RouteForgotPassword/RouteForgotPassword")} fallback={<LoginSkeleton/>} />
			</Route>);
			*/
			r.push(<Route key="login" path="/" exact={true}>
				<LazyLoader component={() => import("../RouteLogin/RouteLogin")} fallback={<LoginSkeleton/>} />
			</Route>);

			r.push(
				<Route key="matchAll" path="*">
					<Redirect to={{pathname: "/404"}}/>
				</Route>
			);

			return r;
		}

		const authL = parseInt(authLevel || 2);

		switch(authL){
			case 1:
				r.push(<Route key="noencontrados" path="/noencontrados">
					<LazyLoader component={() => import("../RouteNoEncontrados/RouteNoEncontrados")} fallback={<NoEncontradosSkeleton/>}/>
				</Route>);

				r.push(<Route key="rutas" path="/rutas">
					<LazyLoader component={() => import("../RouteRutas/RouteRutas")} fallback={<ABMSkeleton/>}/>
				</Route>);

				r.push(<Route key="abm" path="/abm/:table">
					<LazyLoader component={() => import("../RouteABM/RouteABM")} fallback={<ABMSkeleton/>}/>
				</Route>);

				r.push(<Route key="abmupdate" path="/abmupdate/:table/:id">
					<LazyLoader component={() => import("../RouteABMUpdate/RouteABMUpdate")} fallback={<ABMUpdateSkeleton/>}/>
				</Route>);

				r.push(<Route key="abmdelete" path="/abmdelete/:table/:id">
					<LazyLoader component={() => import("../RouteABMDelete/RouteABMDelete")} fallback={<ABMDeleteSkeleton/>}/>
				</Route>);
			default:

				r.push(<Route key="fraudes" path="/fraudes">
					<LazyLoader component={() => import("../RouteFraudes/RouteFraudes")} fallback={<NoEncontradosSkeleton/>}/>
				</Route>);

				r.push(<Route key="scanner" path="/scanner">
					<LazyLoader component={() => import("../Scanner/Scanner")} fallback={<ScannerSkeleton/>}/>
				</Route>);

				r.push(<Route key="rutanota" path="/rutanota/:id">
					<LazyLoader component={() => import("../RutaNota/RutaNota")} fallback={<ScannerSkeleton/>}/>
				</Route>);

				r.push(
					<Route key="myuser" path="/miusuario" >
						<LazyLoader component={() => import("../RouteMyUser/RouteMyUser")} fallback={<MyUserSkeleton/>} />
					</Route>
				);
				r.push(
					<Route key="wall" path="/" exact={true}>
						<LazyLoader component={() => import("../RouteWall/RouteWall")} fallback={<WallSkeleton/>} />
					</Route>
				);
				r.push(
					<Route key="matchAll" path="*">
						<Redirect to={{pathname: "/404"}}/>
					</Route>
				);
				break;
		}
		return r;
	}, [authLevel, isLogged]);



	return (
		<div ref={renderArea} className="main-content">
			<Switch location={background || location}>
				{ routes }
			</Switch>
			{background &&
				<Modal outCallback={goBack} iconButton="times">
					<Switch>
						{ routes }
					</Switch>
				</Modal>
			}
		</div>
	);
});
