import qs from 'qs';
import store from '../../Store';
import { requestStart, requestStop } from '../../Store/actions/http';

export class HTTPClient{
	
	_statusCheck(response){
		store.dispatch(requestStop());
		
		switch(response.status){
			case 200:
			case 201:
			case 202:
			case 401:
				return response;
			default:
				const error = new Error(response.statusText);
				error.response = response;
				throw error;
		}
	}
	
	_isFile(file){
		return file != null && 
			(
				file instanceof Blob || 
				file instanceof File || 
				(file.flashId && file.name && file.size)
			);
	}
	
	_addValueToFormData(formData, key, value){
		let returnFormData = false;	
		if( this._isFile(value) ){
			
			returnFormData = true;
			formData.append(key, value, value.name);
			
		}else if(Array.isArray(value)){
			
			for(let kkey in value){
				let j = this._addValueToFormData(formData, key, value[kkey]);
				if(j && !returnFormData)
					returnFormData = j;
			}
			
		}else if(typeof val === "object"){
			
			for(let kkey in value){
				let j = this._addValueToFormData(formData, key+"."+kkey, value[kkey]);
				if(j && !returnFormData)
					returnFormData = j;
			}
			
		}else
			formData.append(key, value);
		
		return returnFormData;
	}
	
	_transformBody(options = {}){
		if(!('body' in options) || Object.keys(options.body).length === 0)
			return;
	
		let returnFormData = false;
		const formData = new FormData();
		
		for(const key in options.body){
			const fdata = this._addValueToFormData(formData, key, options.body[key]);
			
			if(!returnFormData)
				returnFormData = fdata;
		}
		
		if(returnFormData){
			options.headers['Content-Type'] = 'multipart/form-data;charset=utf-8';
			options.body = formData;
		}else{
			options.headers['Content-Type'] = 'application/json;charset=utf-8';
			options.body = JSON.stringify(options.body);
		}
	}
	
	_request(url = '', options = {}){
		options.url = url;
		
		if(!('headers' in options))
			options.headers = {};
		
		this._transformBody(options);
		
		/*const promises = [];
		
		this.requestTransformers.forEach( transformer => {
			promises.push(transformer(options))
		});*/
		
		store.dispatch(requestStart());
		
		/*return Promise.all(promises)
		.then(() => {*/
			let querystring = qs.stringify(options.query, { indices: false, addQueryPrefix: true });
			return fetch(new URL(`${options.url}${querystring}`), options)/*;
		}, e => {
			store.dispatch(requestStop);
			throw e;
		})*/
		.then(this._statusCheck);
	}
	
	/*constructor(){
		//this.requestTransformers = new Set();
	}*/
	
	/*addRequestTransformers(transformer){
		this.requestTransformers.add(transformer);
	}
	
	removeRequestTransformer(transformer){
		this.requestTransformers.delete(transformer);
	}*/
	
	get(url = '', query = {}, config = {}){
		const options = {...config};
		options.method = 'GET';
		options.query = query;
		return this._request(url, options);
	}
	
	delete(url = '', query = {}, config = {}){
		const options = {...config};
		options.method = 'DELETE';
		options.query = query;
		return this._request(url, options);
	}
	
	post(url = '', query = {}, body = {}, config = {}){
		const options = {...config};
		options.method = 'POST';
		options.body = body;
		options.query = query;
		return this._request(url, options);
	}
	
	put(url = '', query = {}, body = {}, config = {}){
		const options = {...config};
		options.method = 'PUT';
		options.body = body;
		options.query = query;
		return this._request(url, options);
	}

};
