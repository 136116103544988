import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem } from '../Sidebar/MenuItem/MenuItem';
//import { MenuHeader } from '../Sidebar/MenuHeader/MenuHeader';
//import { MenuDropdown } from '../Sidebar/MenuDropdown/MenuDropdown';

import { library } from '@fortawesome/fontawesome-svg-core';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faUserNinja } from '@fortawesome/free-solid-svg-icons';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { faRoute } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

library.add(faHome);
library.add(faUsers);
library.add(faUserNinja);
library.add(faTachometerAlt);
library.add(faRoute);
library.add(faExclamationTriangle);

export const MenuLinks = React.memo(function(){
	const authLevel = useSelector(state => state.auth.user.type);

	const links = useMemo( () => {
		const childs = [
		];


		if(void 0 !== authLevel){
			childs.push(<MenuItem text="menu.home" key="/" to="/" exact="true" icon="home"/>);

			switch(parseInt(authLevel)){
				case 1:
					childs.push(<MenuItem text="menu.users" key="/abm/users" to="/abm/users" icon="users"/>);
					childs.push(<MenuItem text="menu.rutas" key="/rutas" to="/rutas" icon="route"/>);
					childs.push(<MenuItem text="menu.medidores" key="/abm/medidores" to="/abm/medidores" icon="tachometer-alt"/>);
					childs.push(<MenuItem text="menu.fraudes" key="/fraudes" to="/fraudes" icon="user-ninja"/>);
					childs.push(<MenuItem text="menu.noencontrados" key="/noencontrados" to="/noencontrados" icon="exclamation-triangle"/>);
					break;
				case 2:
					childs.push(<MenuItem text="menu.fraudes" key="/fraudes" to="/fraudes" icon="user-ninja"/>);
					break;
				default:
					break;
			}
		}
		return childs;
	}, [authLevel]);



	return (
		<>
			{ links }
		</>
	);
});
