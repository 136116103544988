import React from 'react';
import { HeroImage } from '../HeroImage/HeroImage';
import { HeroCard } from '../HeroCard/HeroCard';
import { SkeletonBlock } from '../SkeletonBlock/SkeletonBlock';

export default function(){
	return (
		<HeroImage>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-6 col-md-2 col-lg-1">
						<SkeletonBlock height={'100px'} width={'100%'} />
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-12 col-md-6">
						<HeroCard header={<h5 className="form-title"><SkeletonBlock height={'36px'} width={'140px'} /></h5>}>
							<SkeletonBlock height={'48px'} width={'100%'} />
							<SkeletonBlock height={'48px'} width={'100%'} />
						</HeroCard>
					</div>
				</div>
			</div>
		</HeroImage>
	);
};