import { useEffect } from "react";

export default function useInView(ref, callback, active, margin = '0px') {
	useEffect(() => {
		const element = ref.current;
		
		if(!active || element == null)
			return;
		
		if(!('IntersectionObserver' in window)){
			callback();
			return;
		}
		
		let done = false;
		let timer;
		
		const obs = new IntersectionObserver(entries => {
			entries.forEach(({ isIntersecting }) => {
				if (isIntersecting) {
					timer = setTimeout(() => {
						done = true;
						callback();
						obs.unobserve(element);
						obs.disconnect();
					}, 100);
				}else{
					if(timer){
						clearTimeout(timer);
						timer = null;
					}
				}
			});
		}, 
		{
			threshold : [0.3],
			rootMargin : margin
		});
		
		obs.observe(element);
		
		return () => {
			if(done)
				return;
			
			if(timer)
				clearTimeout(timer);
			
			obs.unobserve(element);
			obs.disconnect();
		};
		
	}, [margin, ref, callback, active]);
};