import React, { useState, useCallback } from 'react';
import './Modal.scss';
import { createPortal } from 'react-dom';
import usePortal from '../../Hooks/usePortal';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const Modal = React.memo(function({children, outCallback, iconButton}){
	const target = usePortal('modal-root');
	const theme = useSelector(state => state.theme.color);
	const [fading, setFading] = useState(false);
	
	const onClick = useCallback(() => {
		
		if(outCallback === void 0) return;
		
		setFading(true);
		setTimeout(() => {
			outCallback();
		},510);
		
	}, [outCallback]);
	
	return createPortal(
		(
		<>
			<div className={`modal-fade${fading ? ' fading' : ''}`} onClick={onClick}></div>
			<div className={`modal-overlay${fading ? ' fading' : ''} theme-${theme}`} >
				
				<div className="container py-5">
					<div className="modal-content">
					{iconButton !== void 0 &&<div className="close-button" onClick={onClick}>
						<FontAwesomeIcon icon={iconButton} size="lg"/>
					</div>}
						{ children }
					</div>
				</div>
			</div>
		</>),
		target
	);
});