import { actionCreator } from './_actionCreator';

export const NAVBAR_TOGGLE = "NAVBAR_TOGGLE";
export const NAVBAR_CLOSE = "NAVBAR_CLOSE";
export const NAVBAR_OPEN = "NAVBAR_OPEN";

export const toggleNavbar = actionCreator(NAVBAR_TOGGLE);
export const closeNavbar = actionCreator(NAVBAR_CLOSE);
export const openNavbar = actionCreator(NAVBAR_OPEN);

const initialState = false;

const reducer = function(state = initialState, action){
	switch(action.type){
		case NAVBAR_TOGGLE:
			return !state;
		case NAVBAR_OPEN:
			return true;
		case NAVBAR_CLOSE:
			return false;
		default:
			return state;
	}
};

export const navbar = {persist : false, reducer};