import React, { useCallback } from 'react';
import './Sidebar.scss';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../Assets/logo.png';
import _logo from '../../Assets/_logo.svg';
import { toggleNavbar } from '../../Store/actions/navbar';
import Media from 'react-media';
import { Image } from '../Image/Image';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

library.add(faTimes);

export const Sidebar = React.memo(function({children}){
	
	const theme = useSelector(state => state.theme.color);
	const isOpen = useSelector(state => state.navbar);
	const usermail = useSelector(state => state.auth.user.mail);
	
	const dispatch = useDispatch();
	
	const toggle = useCallback(() => dispatch(toggleNavbar()) , [dispatch]);
	
	return (
		<div className={ `sidebar ${isOpen ? 'open' : ''}`}>
			<div className="navbar-wrap">
				<div className="logo">
					<div className="logo-wrap">
						<Link to="/">
							<Image src={ theme === 'light' ? logo : _logo} alt="app.name"/>
						</Link>
						<span className="username">
							{ usermail }
						</span>
						<Media query="(max-width : 768px)">
							<div className="close" onClick={toggle}>
								<FontAwesomeIcon icon="times" size="lg" />
							</div>
						</Media>
					</div>
				</div>
				<div className="menu">
					<div className="main-menu-wrap">
						<div className="menu-ul">
							{
								children
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});