import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import { Text } from "../../Text/Text";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { closeNavbar } from '../../../Store/actions/navbar';

const NullLink = React.memo(({children, ...props}) => {
	return (
		<span {...props}>
			{ children }
		</span>
	);
});

export const _MenuItem = function({text, icon, ...props}){
	const match = useRouteMatch({ path: props.to, exact : props.exact });
	
	const WrapperEl = useRouteMatch({ path: props.to, exact : true }) ? NullLink : Link;
	
	const dispatch = useDispatch();
	const isNavOpen = useSelector(state => state.navbar);
	
	const close = useCallback(() => {
		if(isNavOpen)
			dispatch(closeNavbar());
	}, [dispatch, isNavOpen]);
	
	return (
		<li className={`menu-item ${ match ? 'active' : ''}`}>
			<WrapperEl {...props} className={`item-link ${ match ? 'active' : ''}`} onClick={close}>
				{
					icon && <span className="link-icon">
						<FontAwesomeIcon icon={icon} />
					</span>
				}
				<span className="link-text">
					<Text text={text} />
				</span>
			</WrapperEl>
		</li>
	);
};

_MenuItem.propTypes = {
	to: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export const MenuItem = React.memo(_MenuItem);