import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import '../Forms/Forms.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar } from '../Sidebar/Sidebar';
import { Header } from '../Header/Header';
import { MainContent } from '../MainContent/MainContent';
import { toggleNavbar } from '../../Store/actions/navbar';
import Media from 'react-media';
import { MenuLinks } from '../MenuLinks/MenuLinks';
import { useHistory } from "react-router-dom";
import 'moment/locale/es';

const App = function(){
	const history = useHistory();
	const isLogged = useSelector(state => state.auth.logged);
	const [initialAuth, setInitialAuth] = useState(isLogged);
	
	const isNavbarOpen = useSelector(state => state.navbar);
	const dispatch = useDispatch();
	
	const toggle = useCallback(() => dispatch(toggleNavbar()) , [dispatch]);
	const theme = useSelector(state => state.theme.color);
	
	useEffect(() => {
		if(initialAuth && !isLogged)
			history.push('/');
		
		setInitialAuth(isLogged);
	}, [initialAuth, isLogged, history])
	
	return (
		<div className={`App theme-${theme}`}>
			<div className={ `app-container ${ isLogged ? 'logged' : '' }` } >
				{ isLogged && <Sidebar>
					<MenuLinks />
				</Sidebar>}
				{ isNavbarOpen && 
					<Media query="(max-width : 768px)">
						<div className="overlay" onClick={toggle}></div>
					</Media>
				}
				{ isLogged && <Header/> }
				<MainContent />
			</div>
		</div>
	);
}

export default App;
