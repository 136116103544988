import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import './DropDown.scss';
import useOutsideAlerter from '../../Hooks/useOutsideAlerter';

const _DropDown = function({trigger, content}){
	const [isActive, setIsActive] = useState(false);
	const [fading, setFading] = useState(false);
	
	const [triggerRect, setTriggerRect] = useState(null);
	const [contentRect, setContentRect] = useState(null);
	
	const wrapperRef = useRef(null);
	
	const onTriggerClick = useCallback(() => {
		if(fading)
			return;
		setIsActive(true);
		
	}, [fading]);
	
	const closeCB = useCallback(() => {
		setFading(true);
	}, []);
	
	useEffect(() => {
		if(!fading)
			return;
		
		const timer = setTimeout(() => {
			setFading(false);
			setIsActive(false);
		}, 550);
		
		
		return () => clearTimeout(timer);
	}, [fading]);
	
	
	useOutsideAlerter(wrapperRef, closeCB, isActive);
	
	const measuredTrigger = useCallback(node => {
		if (node !== null) {
			const {x, y, height, width} = node.getBoundingClientRect();
			setTriggerRect({x, y, height, width});
		}
	}, []);
	
	const measuredContent = useCallback(node => {
		if (node == null) return;
		const {x, y, height, width} = node.getBoundingClientRect();
		setContentRect({x, y, height, width});
	}, []);
	
	const style = useMemo(() => {
		if(!isActive || triggerRect == null || contentRect == null)
			return {};
		
		const newStyle = {
		};
		
		if(triggerRect.y + triggerRect.height + contentRect.height + 5 < window.innerHeight){
			newStyle.top = triggerRect.height + 5;
		}else if(window.innerHeight - triggerRect.height - 10 >= contentRect.height){
			newStyle.bottom = triggerRect.height + 5;
		}else
			newStyle.top = triggerRect.height + 5;
		
		if(triggerRect.x - triggerRect.width >= contentRect.width){
			newStyle.right = 0;
		}else if(triggerRect.x + contentRect.width < window.innerWidth){
			delete newStyle.right;
			newStyle.left = 0;
		} 
		
		return newStyle; 
	}, [isActive, contentRect, triggerRect]);
  
	return (
		<div ref={wrapperRef} className="dropdown-wrapper">
			<div className="dropdown-trigger" onClick={onTriggerClick} ref={measuredTrigger}>
				{ trigger }
			</div>
			{isActive && <div className={`dropdown-content${fading ? ' fading' : ''}`} style={style} ref={measuredContent}>
				{ content }
			</div>}
		</div>
	);
	
};


export const DropDown = React.memo(_DropDown);