import React from 'react';
import { SkeletonBlock } from '../SkeletonBlock/SkeletonBlock';
import "./Medidor.scss";

export const MedidorSkeleton = React.memo(function({animate}){

	return  <div className="col-12 col-md-4 col-lg-3">
				<div className={`project-update${ animate ? ' entering' : ''}`}>
					<div className="project-update-header">
						<div className="project-update-user-photo theme-dark ghostLoad">
							
						</div>
						<div className="project-update-header-info">	
							<span className="project-update-project">
								<SkeletonBlock width="80px" height="25px"/>
							</span>
							<span className="project-update-user">
								<SkeletonBlock width="50px" height="19px"/>
							</span>
							<span className="project-update-created">
								<SkeletonBlock width="60px" height="16px"/>
							</span>
							<span className="project-update-created">
								<SkeletonBlock width="60px" height="16px"/>
							</span>
							<span className="project-update-created">
								<SkeletonBlock width="60px" height="16px"/>
							</span>
						</div>
					</div>
				</div>
			</div>;
});