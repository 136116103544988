import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from '../Lib/Lang';

const langs = {};

export const useLangArr = function(args){
	const lang = useSelector(state => state.lang);
	
	if(lang in langs){
		for(const index in args){
			if(args[index] in langs[lang]){
				args[index] = langs[lang][args[index]];
			}
		}
	}
	
	const [text, setText] = useState(args);
	
	useEffect(() => {
		if(text.length === 0) return;
		
		let cancel = false;
		
		getLang(lang)
		.then(langAsset => {
			if(cancel) return;
			
			langs[lang] = langAsset;
			let update = false;
			let newText = [];
			for(const key of text){
				if(key in langAsset){
					update = true;
					newText.push(langAsset[key]);
				}else{
					newText.push(key);
				}
			}
			
			if(update)
				setText(newText);
		})
		.catch((e) => {});
		
		return () => { cancel = true; };
	}, [lang, text]);
	
	return text;
};

export default function useLang(key){
	
	const lang = useSelector(state => state.lang);
	if(lang in langs){
		if(key in langs[lang]){
			key = langs[lang][key];
		}
	}
	
	const [notFound, setNotFound] = useState(false);
	const [text, setText] = useState(key);
	
	useEffect(() => {
		setText(key);
	}, [key])
	
	useEffect(() => {
		if(text == null || text === void 0 || notFound) return;
		
		let cancel = false;
		
		getLang(lang)
		.then(langAsset => {
			if(cancel) return;
			
			langs[lang] = langAsset;
			if(text in langAsset){
				setText(langAsset[text]);
			}else{
				setNotFound(true);
			}
		})
		.catch((e) => {});
		
		return () => { cancel = true; };
	}, [lang, text, notFound]);
	
	return text;
};