const object = {
	dictKey : "dict://",
	reCaptcha : "6LfaqMAUAAAAAA3pzxGRa_Qsxvqavdd9W1xHAWGh",
	localstorageKey : "medidoresv1",
	endpoints: Object.freeze({
		auth : 'auth',
		users : 'users',
		medidores : 'medidores',
		rutas : 'rutas',
		noencontrados : 'noencontrados'
	}),
	dictionaryIndexes : Object.freeze({
		users : 0,
		medidores : 5,
		rutas : 5,
		noencontrados : 15
	})
};

switch(process.env.NODE_ENV){
	case 'production':
		object.apiURL = "http://medidores.sistemas.nixel.com.ar/api/v1/";
		object.uploadsURL = "/uploads/";
		break;
	default:
		object.apiURL = "http://localhost/nixelmedidores/v1/";
		object.uploadsURL = "http://localhost/nixelmedidores/uploads/";
		break;
}

Object.freeze(object);
export default object;
