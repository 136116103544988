import { actionCreator } from './_actionCreator';

export const SET_RUTA = "SET_RUTA";

export const setRuta = actionCreator(SET_RUTA);

const initialState = -1;

const reducer = function(state = initialState, action){
	switch(action.type){
		case SET_RUTA:
			return parseInt(action.payload);
		default:
			return state;
	}
};

export const ruta = {persist : true, reducer};