import React from 'react';
import './RouteWall.scss';
import { MedidorSkeleton } from '../Medidor/MedidorSkeleton';

const skeleton = [
	<MedidorSkeleton key={0} animate={true}/>,
	<MedidorSkeleton key={1} animate={true}/>,
	<MedidorSkeleton key={2} animate={true}/>,
	<MedidorSkeleton key={3} animate={true}/>,
	<MedidorSkeleton key={4} animate={true}/>,
	<MedidorSkeleton key={5} animate={true}/>,
	<MedidorSkeleton key={6} animate={true}/>,
	<MedidorSkeleton key={7} animate={true}/>,
	<MedidorSkeleton key={8} animate={true}/>,
	<MedidorSkeleton key={9} animate={true}/>,
	<MedidorSkeleton key={10} animate={true}/>,
	<MedidorSkeleton key={11} animate={true}/>,
	<MedidorSkeleton key={12} animate={true}/>,
	<MedidorSkeleton key={13} animate={true}/>,
	<MedidorSkeleton key={14} animate={true}/>,
	<MedidorSkeleton key={15} animate={true}/>,
	<MedidorSkeleton key={16} animate={true}/>,
	<MedidorSkeleton key={17} animate={true}/>,
	<MedidorSkeleton key={18} animate={true}/>,
	<MedidorSkeleton key={19} animate={true}/>,
	<MedidorSkeleton key={20} animate={true}/>,
	<MedidorSkeleton key={21} animate={true}/>,
	<MedidorSkeleton key={22} animate={true}/>,
	<MedidorSkeleton key={23} animate={true}/>,
	<MedidorSkeleton key={24} animate={true}/>,
	<MedidorSkeleton key={25} animate={true}/>,
	<MedidorSkeleton key={26} animate={true}/>,
	<MedidorSkeleton key={27} animate={true}/>,
	<MedidorSkeleton key={28} animate={true}/>,
	<MedidorSkeleton key={29} animate={true}/>,
	<MedidorSkeleton key={30} animate={true}/>,
	<MedidorSkeleton key={31} animate={true}/>,
	<MedidorSkeleton key={32} animate={true}/>,
	<MedidorSkeleton key={33} animate={true}/>,
	<MedidorSkeleton key={34} animate={true}/>,
	<MedidorSkeleton key={35} animate={true}/>
];

export default React.memo(() => (
	<div className="container-fluid py-2">
			<div className="row">
				{ skeleton }
			</div>
		</div>
	)
);